var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hk-dice-text"},[_vm._l((_vm.splitOnRollable(_vm.input_text)),function(line,index){return [(line.dice === undefined)?[_vm._v("\n\t\t\t"+_vm._s(line.value)+"\n\t\t")]:_c('hk-roll',{key:index,attrs:{"tooltip":`Roll ${_vm.dice2str(line.dice)}`,"roll":{
				n: line.dice.n,
				d: line.dice.d,
				m: line.dice.m,
				title: _vm.roll_title || `Roll ${_vm.dice2str(line.dice)}`,
				entity_name: _vm.entity_name,
				notify: true
			}}},[_c('strong',{staticClass:"rollable"},[_vm._v("\n\t\t\t\t"+_vm._s(line.value)+"\n\t\t\t")])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }